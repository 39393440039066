import axios from "axios";
const ENDPOINT = "/api/v1/division-grades";

const get = async payload => {
  return axios.get(ENDPOINT, { params: payload });
};

const create = async payload => {
  return await axios.post(ENDPOINT, payload);
};

const update = async payload => {
  return await axios.put(ENDPOINT, payload);
};

const remove = async payload => {
  return axios.delete(ENDPOINT + "/" + payload);
};

export { get, create, update, remove };
